import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import dayjs, { Dayjs } from 'dayjs'
import { DateRangePicker } from '../date/DateRangePicker'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import DateRangeIcon from '@mui/icons-material/DateRange';

export type dateRangeFilters = {
    startDate: Dayjs | null,
    endDate: Dayjs | null,
    selectedQuickFilter: quickFilter | null,
    includePast: boolean,
    includeWithoutDate: boolean,
}

type reminderDateRangeProps = {
    filters: dateRangeFilters,
    setFilters: React.Dispatch<React.SetStateAction<dateRangeFilters>>,
}

export type quickFilter = {
    choice: 'past' | 'today' | 'current' | 'next',
    amount?: number,
    unit?: 'day' | 'week' | 'month',
}

const unitTranslation = (unit: 'day' | 'week' | 'month', amount: number) => {
    let unitStr = {
        'day': 'day',
        'week': 'week',
        'month': 'month',
    }
    let unitTranslation : { [key: string]: string; } = {
        "day_one": 'Day',
        "day_other": 'Days',
        "week_one": 'Week',
        "week_other": 'Weeks',
        "month_one": 'Month',
        "month_other": 'Month',
    }

    return amount === 1 
        ? unitTranslation[unitStr[unit] + '_one']
        : unitTranslation[unitStr[unit] + '_other']
}

export const quickFilterToString = (filter: quickFilter) => {
    switch(filter.choice) {
        case 'past':
            return 'Past'
        case 'today':
            return 'Today'
        case 'current':
            return filter.unit !== undefined ? `Current ${unitTranslation(filter.unit, 1)}` : ''
        case 'next':
            return (filter.unit !== undefined && filter.amount !== undefined) ? `Next ${filter.amount} ${unitTranslation(filter.unit, filter.amount)}` : ''
    }
}

type dateMenuCloseProps = {
    onClose: () => void
}

export default function ReminderDateRange(filter: reminderDateRangeProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }

    return (
        <>
            <Grid container justifyContent="center">       
                <Button
                    id="daterange-button"
                    aria-controls={open ? 'daterange-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    size="small"
                    sx={{padding: 0}}
                >
                    <DateRangeIcon /> <ArrowDropDownIcon />
                </Button>
                <Menu
                    id="daterange-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'daterange-button',
                    }}
                >
                    <MenuItem>
                        <DateMenu 
                            filters={filter.filters}
                            setFilters={filter.setFilters}
                            onClose={handleClose}
                        />
                    </MenuItem>
                </Menu>
            </Grid>
        </>
    )
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
    
  function DateMenu(filter: reminderDateRangeProps & dateMenuCloseProps) {
    const [value, setValue] = React.useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
      };

    const resetDates = () => {
        filter.setFilters({
            ...filter.filters, 
            startDate: null, 
            endDate: null, 
            selectedQuickFilter: null
        })
        filter.onClose()
    }
    const setPast = () => {
        filter.setFilters({
            ...filter.filters, 
            endDate: dayjs().startOf('day'), 
            selectedQuickFilter: {choice: 'past'}
        })
        filter.onClose()
    }
    const setToday = () => {
        filter.setFilters({
            ...filter.filters, 
            startDate: dayjs().startOf('day'), 
            endDate: dayjs().endOf('day'), 
            selectedQuickFilter: {choice: 'today'}
        })
        filter.onClose()
    }
    const setCurrentWeek = () => {
        filter.setFilters({
            ...filter.filters, 
            startDate: dayjs().startOf('week'), 
            endDate: dayjs().endOf('week'), 
            selectedQuickFilter: {choice: 'current', unit: 'week'}
        })
        filter.onClose()
    }
    const setNextTwoWeeks = () => {
        filter.setFilters({
            ...filter.filters, 
            startDate: dayjs().startOf('week'), 
            endDate: dayjs().add(1, 'week').endOf('week'), 
            selectedQuickFilter: {choice: 'next', amount: 2, unit: 'week'}
        })
        filter.onClose()
    }
    const setNextFourWeeks = () => {
        filter.setFilters({
            ...filter.filters, 
            startDate: dayjs().startOf('week'), 
            endDate: dayjs().add(3, 'week').endOf('week'), 
            selectedQuickFilter: {choice: 'next', amount: 4, unit: 'week'}
        })
        filter.onClose()
    }
    const setCurrentMonth = () => {
        filter.setFilters({
            ...filter.filters, 
            startDate: dayjs().startOf('month'), 
            endDate: dayjs().endOf('month'), 
            selectedQuickFilter: {choice: 'current', unit: 'month'}
        })
        filter.onClose()
    }

    return (
        <Box sx={{ width: 320, maxWidth: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Quick Range" id="simple-tab-0" aria-controls='simple-tabpanel-0' />
                <Tab label="Date Range" id="simple-tab-1" aria-controls='simple-tabpanel-1' />
            </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Stack spacing={1}>
                    <Button size="small" color="primary" onClick={resetDates}>Show All</Button>
                    <Button size="small" color="primary" onClick={setPast}>Show Past</Button>
                    <Button size="small" color="primary" onClick={setToday}>Today</Button>
                    <Button size="small" color="primary" onClick={setCurrentWeek}>Current week</Button>
                    <Button size="small" color="primary" onClick={setNextTwoWeeks}>Next 2 weeks</Button>
                    <Button size="small" color="primary" onClick={setNextFourWeeks}>Next 4 weeks</Button>
                    <Button size="small" color="primary" onClick={setCurrentMonth}>Current month</Button>
                </Stack>   
            </TabPanel>
            <TabPanel value={value} index={1}> 
                <DateRangePicker 
                    startDate={filter.filters.startDate || undefined}
                    endDate={filter.filters.endDate || undefined}
                    onChange={(startDate: Dayjs | null, endDate: Dayjs | null) => {filter.setFilters({...filter.filters, startDate: startDate, endDate: endDate, selectedQuickFilter: null}); filter.onClose();}}
                />
            </TabPanel>
            <FormGroup>
                {value === 0 && <FormControlLabel 
                    control={<Switch 
                        checked={filter.filters.includePast} 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {filter.setFilters({...filter.filters, includePast: event.target.checked})}} 
                    />} 
                    label="Include the Past" 
                />}
                <FormControlLabel 
                    control={<Switch 
                        checked={filter.filters.includeWithoutDate} 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {filter.setFilters({...filter.filters, includeWithoutDate: event.target.checked})}} 
                    />} 
                    label="Include Reminders without Date" 
                />
            </FormGroup>
        </Box>
    );
  }