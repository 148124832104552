import * as React from 'react'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import NoteAdd from '@mui/icons-material/NoteAdd'
import Note from '@mui/icons-material/Note'
import Build from '@mui/icons-material/Build'
import Paper from '@mui/material/Paper'
import LabelIcon from '@mui/icons-material/Label'
import { Link } from "react-router-dom"
import Container from '@mui/material/Container'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { selectReminderFilters, ReminderFilterState } from '../reminder/reminderFilterSlice'
import { useSelector } from 'react-redux'
import {isLocalhost} from '../env/environment'

export default function MyBottomNavigation() {
  const [value, setValue] = React.useState(window.location.pathname)
  const reminderFilters = useSelector(selectReminderFilters)
  const [reminderFilterMenuAnchorEl, setReminderFilterMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const openReminderFilterMenu = Boolean(reminderFilterMenuAnchorEl);

  const renderShowRemindersMenuItem = () => {
    if (reminderFilters.length > 0) {
      return (
        <BottomNavigationAction 
          id="nav-show-reminder-menu-button"
          aria-controls={openReminderFilterMenu ? 'nav-show-reminder-menu-button' : undefined}
          aria-haspopup="true"
          aria-expanded={openReminderFilterMenu ? 'true' : undefined}
          onClick={handleReminderFilterMenuClick}
          value="/" 
          icon={<Note />} 
        />
      )
    } else {
      return (
        <BottomNavigationAction component={Link} id="nav-show-reminders" to="/show_reminders" value="/show_reminders" icon={<Note />} />
      )
    }
  }
  const handleReminderFilterMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setReminderFilterMenuAnchorEl(event.currentTarget);
  };
  const handleReminderFilterMenuClose = () => {
    setReminderFilterMenuAnchorEl(null);
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <Container maxWidth="md">
        <BottomNavigation 
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          {renderShowRemindersMenuItem()}
          <BottomNavigationAction component={Link} id="nav-edit-reminder" to="/edit_reminder" value="/edit_reminder" icon={<NoteAdd />} />
          <BottomNavigationAction component={Link} id="nav-labels" to="/labels" value="/labels" icon={<LabelIcon />} />
          {isLocalhost && <BottomNavigationAction component={Link} id="nav-todo" to="/todo_list" value="/todo_list" icon={<Build />} />}
        </BottomNavigation>
      </Container>
      <Menu
        id="nav-show-reminder-menu"
        anchorEl={reminderFilterMenuAnchorEl}
        open={openReminderFilterMenu}
        onClose={handleReminderFilterMenuClose}
        MenuListProps={{
          'aria-labelledby': 'nav-show-reminder-menu-button',
        }}
      >
        <MenuItem 
            id="show_all"
            component={Link} 
            to="/show_reminders/"
            onClick={handleReminderFilterMenuClose}
          >
            Show All
        </MenuItem>
        {reminderFilters.map((reminderFilter: ReminderFilterState) => (
          <MenuItem 
            id={"show_" + reminderFilter.id}
            component={Link} 
            to={"/show_reminders/" + reminderFilter.id}
            onClick={handleReminderFilterMenuClose}
            key={"menu_reminder_filter_" + reminderFilter.id}
          >
            {reminderFilter.name} 
          </MenuItem>
        ))}
      </Menu>
    </Paper>
  );
}