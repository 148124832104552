import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

export interface LabelState {
  id: string, // unique identifier for each reminder
  name: string, // the subject, or a short description
  hidden: boolean, // Show Reminder only if filtered by label
  bgcolor: string, // background color code for display
  color: string, // text color code for display
}

const sliceName = 'labels'

let initialState: LabelState[] = []

let persistedState = localStorage.getItem(sliceName)
if (persistedState) {
  initialState = JSON.parse(persistedState)
}

const labelSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    labelAdded(state, action: PayloadAction<LabelState>) {
      state.push(action.payload)
    },
    labelUpdated(state, action: PayloadAction<LabelState>) {
      const index = state.findIndex(label => label.id === action.payload.id)

      const nextState = [...state]
      nextState[index] = action.payload

      return nextState
    },
    labelDeleted(state, action: PayloadAction<string>) {
      return state.filter(label => label.id !== action.payload)
    },
  }
})

export const { labelAdded, labelUpdated, labelDeleted } = labelSlice.actions
export const selectLabels = (state: RootState) => state[sliceName]

export default labelSlice.reducer
