import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { getNextDate } from './UpdateReminderStates'
import dayjs from 'dayjs'
import { hasFixedRepetition, hasRelativeRepetition } from './Reminder'

// date and time formats to be used for ReminderState, see see https://day.js.org/docs/en/display/format
export const dateFormat = "YYYY-MM-DD"
export const timeFormat = "HH:mm Z"

export enum RepetitionType {
  None = 0,
  Fixed = 1,
  Relative = 2
}
export enum RepetitionPeriod {
  Daily = 0,
  Weekly = 1,
  Monthly = 2,
  Annualy = 3
}

export interface RepetitionState {
  type: RepetitionType, 
  cycle: number,
  period: RepetitionPeriod
}

export interface ReminderState {
  id: string, // unique identifier for each reminder
  subject: string, // the subject, or a short description 
  date?: string, // a date to be displayed, string format according to variable dateFormat
  time?: string, // a time to be displayed, string format according to variable timeFormat
  archived?: boolean,
  labels: string[], // LabelState.id
  repetition?: RepetitionState
}

const sliceName = 'reminders'

let initialState: ReminderState[] = []

let persistedState = localStorage.getItem(sliceName)
if (persistedState) {
  initialState = JSON.parse(persistedState)
}

const reminderSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reminderAdded(state, action: PayloadAction<ReminderState>) {
      state.push(action.payload)
    },
    reminderArchived(state, action: PayloadAction<string>) {
      const index = state.findIndex(reminder => reminder.id === action.payload)

      const nextState = [...state]
      nextState[index] = {
        ...state[index],
        archived: !state[index]['archived']
      }

      return nextState
    },
    reminderDeleted(state, action: PayloadAction<string>) {
      return state.filter(reminder => reminder.id !== action.payload)
    },
    reminderDone(state, action: PayloadAction<string>) {
      const index = state.findIndex(reminder => reminder.id === action.payload)
      
      if (hasFixedRepetition(state[index])) {
        return state
      }
      if (hasRelativeRepetition(state[index])) {
        const nextState = [...state]
        nextState[index] = {
          ...state[index],
          date: getNextDate(dayjs(), state[index].repetition as RepetitionState).format(dateFormat)
        }
        return nextState
      }

      const nextState = [...state]
      nextState[index] = {
        ...state[index],
        archived: true
      }

      return nextState
    },
    reminderUpdated(state, action: PayloadAction<ReminderState>) {
      const index = state.findIndex(reminder => reminder.id === action.payload.id)

      const nextState = [...state]
      nextState[index] = action.payload

      return nextState
    }
  }
})

export const { reminderAdded, reminderArchived, reminderDone, reminderUpdated, reminderDeleted } = reminderSlice.actions
export const selectReminders = (state: RootState) => state[sliceName]

export default reminderSlice.reducer
