import React, { useMemo, useState } from 'react'

import { Button, Dialog, DialogActions } from '@mui/material'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import dayjs, { Dayjs } from 'dayjs'

import { DateRangePickerCustomPickersDay } from './DateRangePickerCustomPickersDay'

type DateRangePickerProps = {
  startDate?: Dayjs | null
  endDate?: Dayjs | null
  onChange?: (startDate: Dayjs | null, endDate: Dayjs | null) => void
  /**
   * The size of the component.
   * @default 'medium'
   */
  size?: 'small' | 'medium'
  /**
   * @default "Select a period"
   */
  placeholder?: string
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  onChange,
  startDate,
  endDate,
  size = 'medium',
  placeholder = 'Select a period',
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [lastSelectedDate, setLastSelectedDate] = useState<'start' | 'end' | null>(null)
  const [startDateState, setStartDateState] = useState<Dayjs | null>(startDate || null)
  const [endDateState, setEndDateState] = useState<Dayjs | null>(endDate || null)

  const textFieldValue = useMemo(() => {
    if (!startDateState && !endDateState) return placeholder

    if (startDateState && !endDateState)
      return `${startDateState.format('MM/DD/YYYY')}`

    if (!startDateState && endDateState)
      return `Select initial data - ${endDateState.format('MM/DD/YYYY')}`

    return `${startDateState?.format('MM/DD/YYYY')} - ${endDateState?.format('MM/DD/YYYY')}`
  }, [endDateState, placeholder, startDateState])

  const renderSelectedDays = (
    date: Dayjs,
    _selectedDates: Array<Dayjs | null>,
    pickersDayProps: PickersDayProps<Dayjs>
  ) => {
    if (!startDateState) {
      return <PickersDay {...pickersDayProps} />
    }

    const currentStart = startDateState || dayjs()
    const currentEnd = endDateState || dayjs()

    const startIsBeforeEnd = currentStart.isBefore(currentEnd)

    const dayIsBetween = startIsBeforeEnd
      ? (date.isAfter(currentStart) || date.isSame(currentStart, 'day')) &&
        (date.isBefore(currentEnd) || date.isSame(currentEnd, 'day'))
      : (date.isBefore(currentStart) || date.isSame(currentStart, 'day')) &&
        (date.isAfter(currentEnd) || date.isSame(currentEnd, 'day'))
    const isFirstDay = date.isSame(currentStart, 'day')
    const isLastDay = date.isSame(currentEnd, 'day')

    return (
      <DateRangePickerCustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={startIsBeforeEnd ? isFirstDay : isLastDay}
        isLastDay={startIsBeforeEnd ? isLastDay : isFirstDay}
      />
    )
  }

  const clearSelection = () => {
    setStartDateState(null)
    setEndDateState(null)
    setLastSelectedDate(null)
    setOpen(false)
  }

  const onClose = () => {
    if (!endDateState) setEndDateState(startDateState)

    if (onChange) onChange(startDateState, endDateState)

    setOpen(false)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TextField 
            size={size} 
            value={textFieldValue}
            variant="standard" 
            onClick={() => setOpen(true)}            
            InputProps={{
                readOnly: true,
            }}
            sx={{ minWidth: 200 }}
        />

      <Dialog disableEscapeKeyDown open={open}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={startDateState}
          onChange={(newDate: Dayjs | null) => {
            const newDateValue = newDate ? newDate.startOf('day') : null

            if (!lastSelectedDate || lastSelectedDate === 'end') {
              setStartDateState(newDateValue)
              setLastSelectedDate('start')
            } else {
              setEndDateState(newDateValue)
              setLastSelectedDate('end')
            }
          }}
          renderDay={renderSelectedDays}
          renderInput={(params) => <TextField {...params} />}
        />

        <DialogActions>
          <div className="flex w-full flex-row justify-between">
            <Button color="secondary" onClick={clearSelection}>
              Cancel
            </Button>

            <Button color="primary" onClick={onClose}>
              Set Date
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  )
}
