import * as React from 'react'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useAppDispatch } from '../../app/hooks'
import { ReminderState, reminderAdded, dateFormat, timeFormat, RepetitionType, RepetitionPeriod, RepetitionState, selectReminders, reminderUpdated } from './reminderSlice'
import { createRef, ReactNode, useState } from 'react'
import { useNavigate } from "react-router-dom"
import dayjs, { Dayjs } from 'dayjs'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Grid from '@mui/material/Grid'
import { v4 as uuidv4 } from 'uuid'
import SelectLabels from '../label/SelectLabels'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import NativeSelect from '@mui/material/NativeSelect'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

function EditReminder() {
    const dispatch = useAppDispatch()
    const subjectRef = createRef<HTMLInputElement>()
    let navigate = useNavigate()
    const [subjectError, setSubjectError] = useState("")
    const [date, setDate] = useState<Dayjs | null>(null)
    const [time, setTime] = useState<Dayjs | null>(null)
    const [selectedLabels, setSelectedLabels] = React.useState<string[]>([]);

    const [repetitionType, setRepetitionType] = React.useState<RepetitionType>(RepetitionType.None);
    const [repetitionCycle, setRepetitionCycle] = React.useState<number>(1);
    const [repetitionPeriod, setRepetitionPeriod] = React.useState<RepetitionPeriod>(RepetitionPeriod.Weekly);

    const { reminderId } = useParams()
    let reminders: ReminderState[] = useSelector(selectReminders) as ReminderState[]
    
    React.useEffect(() => {    
        if (undefined === reminderId) {
            return
        }

        let reminder = reminders.filter(reminder => reminder.id === reminderId)[0]

        if (reminder !== null) {
            if (subjectRef.current !== null && subjectRef.current !== undefined) {
                subjectRef.current.value = reminder.subject
            }
            if (reminder.date !== undefined) {
                setDate(dayjs(reminder.date, dateFormat))
            }
            if (reminder.time !== undefined) {
                setTime(dayjs(reminder.time, timeFormat))
            }
            setSelectedLabels(reminder.labels)
            setRepetitionType(reminder.repetition?.type || RepetitionType.None)
            setRepetitionCycle(reminder.repetition?.cycle || 1)
            setRepetitionPeriod(reminder.repetition?.period || RepetitionPeriod.Weekly)
        }
    }, [reminderId, reminders]);

    function saveReminder(event: React.MouseEvent<HTMLElement>) {
        if (!subjectRef.current || subjectRef.current.value === "") {
            setSubjectError("empty subject is not allowed")
            return
        } 

        let repetition: RepetitionState = {
            type: repetitionType, 
            cycle: repetitionCycle,
            period: repetitionPeriod
        }

        let reminder: ReminderState = {
            id: reminderId || uuidv4(),
            subject: subjectRef.current.value,
            date: date ? date.format(dateFormat) : undefined,
            time: time ? time.format(timeFormat) : undefined,
            labels: selectedLabels,
            repetition: repetition
        }

        if (undefined === reminderId) {
            dispatch(reminderAdded(reminder))
        } else {
            dispatch(reminderUpdated(reminder))
        }
        resetInputFields()
        navigate("/show_reminders")
    }

    function resetInputFields() {
        if (subjectRef.current) {
            subjectRef.current.value = ""
        }
        setDate(null)
    }

    function renderSubjectField(): ReactNode {
        return (<TextField 
            fullWidth
            label="Subject"
            variant="standard"
            id="input_subject"
            margin="dense"  
            inputRef={subjectRef}      
            onChange={() => {setSubjectError("")}}
            error={subjectError !== ""}
            helperText={subjectError}
        />)
    }

    function renderDateTimePicker(): ReactNode {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={0}>
                    <Grid item xs={6} id="grid_input_date">
                        <MobileDatePicker
                            label="Date (optional)"
                            value={date}
                            onChange={(newValue: Dayjs | null) => {
                                setDate(newValue);
                            }}
                            renderInput={(params: any) => <TextField {...params} />}
                        />
                    </Grid>
                    <Grid item xs={6} id="grid_input_time">
                        {date !== null &&
                            <MobileTimePicker
                                label="Time (optional)"
                                value={time}
                                onChange={(newValue: Dayjs | null) => {
                                    setTime(newValue);
                                }}
                                renderInput={(params: any) => <TextField {...params} />}
                            />
                         }
                    </Grid>
                </Grid>                
            </LocalizationProvider>
        )
    }

    function renderLabelSelect() {
        return (
            <SelectLabels selectedLabels={selectedLabels} setSelectedLabels={(labels: string[]) => {setSelectedLabels(labels)}} title="Add Labels"/>
        )
    }

    function renderRepetition() {
        /* doesn't repeat
        daily
        weekly on weekday
        monthly on X weekday
        monthly on first / last weekday
        annualy on month day
        every weekday
        custom
        repeat every x day/week/month/year
        repeat on M T W T F S S
        ends never, on, after X occurences */

        return (
            <> 
                <Grid item xs={12} sm={6}>
                    <FormControl>
                        <InputLabel variant="standard" htmlFor="select-repetition-type">
                        Repetition
                        </InputLabel>
                        <NativeSelect
                            value={repetitionType}
                            inputProps={{
                                name: 'repetition',
                                id: 'select-repetition-type',
                            }}
                            onChange={(event) => {setRepetitionType(parseInt(event.target.value))}}
                        >
                            <option value={RepetitionType.None}>Doesn't repeat</option>
                            <option value={RepetitionType.Fixed}>Repetition on specified key dates</option>
                            <option value={RepetitionType.Relative}>Repetition relative to last completion</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>
                {repetitionType > 0 && <Grid item xs={6} sm={3}>
                    <TextField
                        id="repetition-cycle"
                        label={repetitionType === RepetitionType.Fixed ? "every" : "after"}
                        type="number"
                        defaultValue={repetitionCycle}
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event: any) => {setRepetitionCycle(parseInt(event.target.value))}}
                    />
                </Grid>}
                {repetitionType > 0 && <Grid item xs={6} sm={3}>
                    <FormControl>
                        <InputLabel variant="standard" htmlFor="select-repetition-period">
                            Period
                        </InputLabel>
                        <NativeSelect
                            value={repetitionPeriod}
                            inputProps={{
                                name: 'period',
                                id: 'select-repetition-period',
                            }}
                            onChange={(event) => {setRepetitionPeriod(parseInt(event.target.value))}}
                        >
                            <option value={RepetitionPeriod.Daily}>day(s)</option>
                            <option value={RepetitionPeriod.Weekly}>week(s)</option>
                            <option value={RepetitionPeriod.Monthly}>month(s)</option>
                            <option value={RepetitionPeriod.Annualy}>year(s)</option>
                        </NativeSelect>
                    </FormControl>
                </Grid>}
            </>
        )
    }

    function renderCreateButton(): ReactNode {
        return (
            <Button id="button_create_reminder" variant="contained" color="primary" onClick={saveReminder}>{reminderId === undefined ? "Create reminder" : "Update Reminder"}</Button>
        )
    }

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
        >
            <Grid container spacing={3}> 
                <Grid item xs={12}>{renderSubjectField()}</Grid>
                <Grid item xs={12}>{renderDateTimePicker()}</Grid>
                <Grid item xs={12}>{renderLabelSelect()}</Grid> 
                {renderRepetition()}
                <Grid item xs={12}>{renderCreateButton()}</Grid>
            </Grid>
        </Box>
    )
  }

  export default EditReminder