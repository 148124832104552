import * as React from 'react'
import {useSelector} from 'react-redux'
import {selectReminders, ReminderState, reminderDeleted, dateFormat} from './reminderSlice'
import {selectLabels, LabelState } from '../label/labelSlice'
import ListReminders, {sortByDateTimeAsc} from './ListReminders'
import ReminderFilter, {reminderFilterSettings as reminderFilter} from './ReminderFilter'
import {useParams} from 'react-router-dom'
import {ReminderFilterState, selectReminderFilters} from './reminderFilterSlice'
import {useAppDispatch} from '../../app/hooks'
import AlertDelete from '../user_input/AlertDelete'
import ReminderDateRange, {quickFilterToString, dateRangeFilters as reminderDateRange} from './ReminderDateRange'
import dayjs from 'dayjs'
import Chip from '@mui/material/Chip';
import Label from '../label/Label';
import ReminderDisplay, {reminderDisplay} from './ReminderDisplay'
import Save from './Save'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import {Divider} from '@mui/material'

export function intersection(arr1: string[], arr2: string[]) {
  return arr1.filter(x => arr2.indexOf(x) !== -1)
}

export function filterByStartDate(reminder: ReminderState, options: reminderFilter & reminderDateRange) {
  if (options.startDate === null) {
    return true
  }
  if (options.includePast && options.selectedQuickFilter !== null) {
    return true
  }
  if (typeof reminder.date !== 'string') {
    return options.includeWithoutDate
  }
  let rDate = dayjs(reminder.date, dateFormat)

  return options.startDate.isSame(rDate) || options.startDate.isBefore(rDate)
}

export function filterByEndDate(reminder: ReminderState, options: reminderFilter & reminderDateRange) {
  if (options.endDate === null) {
    return true
  }
  if (typeof reminder.date !== 'string') {
    return options.includeWithoutDate
  }
  let rDate = dayjs(reminder.date, dateFormat)

  return options.endDate.isSame(rDate) || options.endDate.isAfter(rDate)
}

const filterHiddenLabelIds = (hiddenLabels: LabelState[],  ids: string[]) => {
  return ids.filter(id => undefined !== hiddenLabels.find(label => label.id === id))
}

const showReminderIfHidden = (reminder: ReminderState, hiddenLabels: LabelState[], hiddenLabelsFiltered: string[]) => {
  let hiddenReminderLabelIds = filterHiddenLabelIds(hiddenLabels, reminder.labels || [])

  if (hiddenReminderLabelIds.length === 0) {
    return true
  }

  return hiddenReminderLabelIds.length === intersection(hiddenReminderLabelIds, hiddenLabelsFiltered).length
}

export const filteredReminders = (reminders: ReminderState[], labels: LabelState[], options: reminderFilter & reminderDateRange) => {
  let hiddenLabels = labels.filter(label => label.hidden)
  let hiddenLabelsFiltered = filterHiddenLabelIds(hiddenLabels, options.selectedLabels)
  
  return reminders
    // searchText needs to be part ob reminder subject 
    .filter(reminder => reminder.subject.includes(options.searchText))
    // reminder needs to contain all filtered labels 
    .filter(reminder => options.selectedLabels.length === 0 || intersection(reminder.labels, options.selectedLabels).length === options.selectedLabels.length) 
    // hidden labels need to be filtered
    .filter(reminder => showReminderIfHidden(reminder, hiddenLabels, hiddenLabelsFiltered))
    // show / hide archived reminders
    .filter(reminder => options.showArchived !== false || reminder.archived !== true)
    // filter by date range
    .filter(reminder => filterByStartDate(reminder, options))
    .filter(reminder => filterByEndDate(reminder, options))
}

export const displaySettingsToChips = (settings: reminderDisplay) => {
  return (<>
    {settings.displayType === 'list' && <Chip label="List" size="small" variant="outlined" />}
    {settings.displayType === 'list' && settings.showLabels && <Chip label="+ Labels" size="small" variant="outlined" />}
    {settings.displayType === 'grid' && <Chip label="Grid" size="small" variant="outlined" />}
    {settings.displayType === 'calendar' && <Chip label="Calendar" size="small" variant="outlined" />}
  </>)
}

export const currentDateRangeSettingsToChips = (settings: reminderDateRange) => {  
  return (<>
      {settings.selectedQuickFilter === null && settings.startDate !== null && <Chip label={"≥ " + settings.startDate.format(dateFormat)} size="small" variant="outlined" />}
      {settings.selectedQuickFilter === null && settings.endDate !== null && <Chip label={"≤ " + settings.endDate.format(dateFormat)} size="small" variant="outlined" />}
      {settings.selectedQuickFilter !== null && <Chip label={quickFilterToString(settings.selectedQuickFilter)} size="small" variant="outlined" />}
      {settings.selectedQuickFilter !== null && settings.includePast && <Chip label="+ Past" size="small" variant="outlined" />}
      {(settings.startDate !== null || settings.endDate !== null) && settings.includeWithoutDate && <Chip label="+ Dateless" size="small" variant="outlined" />}
    </>)
}

export const currentFilterSettingsToChips = (filterSettings: reminderFilter, getLabelFrom: (id: string) => LabelState) => {
  return (<>
    {filterSettings.searchText !== "" && <Chip label={'"' + filterSettings.searchText + '"'} size="small" variant="outlined" />}
    {filterSettings.selectedLabels.map((id) => (
      <Label key={id} label={getLabelFrom(id)} />
    ))}
    {filterSettings.showArchived && <Chip label="Archived" size="small" variant="outlined" />}
    </>)
}

export default function ShowReminders() {
    let reminders: ReminderState[] = useSelector(selectReminders) as ReminderState[]
    const labels: LabelState[] = useSelector(selectLabels)
    const dispatch = useAppDispatch()

    const reminderFilterState: ReminderFilterState[] = useSelector(selectReminderFilters) as ReminderFilterState[]
    const { reminderFilterId } = useParams()

    const [filterSettings, setFilterSettings] = React.useState<reminderFilter>({
      searchText: "", 
      selectedLabels: [], 
      showArchived: false
    })
    const [dateRangeSettings, setDateRangeSettings] = React.useState<reminderDateRange>({
      startDate: dayjs(),
      endDate: dayjs().add(7, 'day'),
      selectedQuickFilter: {choice: 'next', amount: 7, unit: 'day'},
      includePast: true,
      includeWithoutDate: true,
    })
    const [display, setDisplay] = React.useState<reminderDisplay>({
      displayType: 'list',
      showLabels: true,
      showSettings: true
    })

    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedReminderId, setSelectedReminderId] = React.useState("")

    React.useEffect(() => {
      const reminderFilter = reminderFilterState.filter(reminderFilter => reminderFilter.id === reminderFilterId)
      
      if (reminderFilter.length > 0) {
        if (undefined !== reminderFilter[0].filter) {
          setFilterSettings({
            searchText: reminderFilter[0].filter.searchText,
            selectedLabels: reminderFilter[0].filter.selectedLabels,
            showArchived: reminderFilter[0].filter.showArchived || false,
          }) 
        }
        
        if (undefined !== reminderFilter[0].display) {
          setDisplay(reminderFilter[0].display)
        }

        if (undefined !== reminderFilter[0].dateRange) {
          let nextFilter: reminderDateRange = {...dateRangeSettings}
          if (undefined !== reminderFilter[0].dateRange.startDate) {
            nextFilter.startDate = dayjs(reminderFilter[0].dateRange.startDate, dateFormat)
          } else {
            nextFilter.startDate = null
          }
          if (undefined !== reminderFilter[0].dateRange.endDate) {
            nextFilter.endDate = dayjs(reminderFilter[0].dateRange.endDate, dateFormat)
          } else {
            nextFilter.endDate = null
          }
          nextFilter.selectedQuickFilter = reminderFilter[0].dateRange.selectedQuickFilter
          nextFilter.includePast = reminderFilter[0].dateRange.includePast
          nextFilter.includeWithoutDate = reminderFilter[0].dateRange.includeWithoutDate
          setDateRangeSettings(nextFilter)  
        }   
      } else {
        setFilterSettings({
          searchText: "",
          selectedLabels: [],
          showArchived: false,
        })
        setDateRangeSettings({
          startDate: dayjs(),
          endDate: dayjs().add(7, 'day'),
          selectedQuickFilter: {choice: 'next', amount: 7, unit: 'day'},
          includePast: true,
          includeWithoutDate: true,
        })  
      }
    }, [reminderFilterState, reminderFilterId])
  
    const handleDelete = (id: string) => {
      dispatch(reminderDeleted(id))
      setOpenDialog(false);
    };  

    const deleteReminder = (id: string) => {
        setSelectedReminderId(id)
        setOpenDialog(true)
    }

    let options: reminderFilter & reminderDateRange = {
      searchText: filterSettings.searchText,
      selectedLabels: filterSettings.selectedLabels,
      showArchived: filterSettings.showArchived,
      startDate: dateRangeSettings.startDate,
      endDate: dateRangeSettings.endDate,
      selectedQuickFilter: dateRangeSettings.selectedQuickFilter,
      includePast: dateRangeSettings.includePast,
      includeWithoutDate: dateRangeSettings.includeWithoutDate
    }

    function getLabelFrom(id: string) {
      const label = labels.filter(label => label.id === id)
      return label[0]
    }

    const ref = React.useRef(null);

    const menuSx = {padding: '3px 12px', minWidth: 80, maxWidth: 168, flexDirection: 'column', flex: '1'}

    return (
      <>
        <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0 }} elevation={3}>  
          <Container maxWidth="md">
            <Box component="div" sx={{display: "flex", justifyContent: "center"}}>
              <Box component="div" sx={menuSx}> 
                <ReminderDisplay 
                  filters={display}
                  setFilters={setDisplay}
                />  
              </Box>         
              <Box component="div" sx={menuSx}>             
                <ReminderDateRange 
                  filters={dateRangeSettings}
                  setFilters={setDateRangeSettings}
                />
              </Box>
              <Box component="div" sx={menuSx}> 
                <ReminderFilter 
                  filters={filterSettings}
                  setFilters={setFilterSettings}
                  startDate={dateRangeSettings.startDate}
                  endDate={dateRangeSettings.endDate}
                  selectedQuickFilter={dateRangeSettings.selectedQuickFilter}
                  includePast={dateRangeSettings.includePast}
                  includeWithoutDate={dateRangeSettings.includeWithoutDate}
                />
              </Box>
              <Box component="div" sx={menuSx}> 
                <Save 
                  filter={filterSettings}
                  display={display}
                  dateRange={dateRangeSettings}
                />
              </Box>
            </Box>
          </Container>   
        </Paper>
        <Box component="div" sx={{marginTop: 5}}>
          {display.showSettings && <>
            {currentDateRangeSettingsToChips(dateRangeSettings)}
            {currentFilterSettingsToChips(filterSettings, getLabelFrom)}
            <Divider sx={{marginTop: 1}} />
          </>}
          <Box component="div" ref={ref}>
          {ListReminders({
            reminders: filteredReminders(reminders, labels, options), 
            sort: sortByDateTimeAsc, 
            handleDelete: deleteReminder, 
            display: display, 
            ref: ref
          })}
          </Box>
        </Box>
        <AlertDelete open={openDialog} setOpen={setOpenDialog} id={selectedReminderId} handleSubmit={handleDelete} />
      </>
    )
  }