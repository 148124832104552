import React from 'react';
import Container from '@mui/material/Container';
import { Outlet } from "react-router-dom";
import MyBottomNavigation from './features/navigation/MyBottomNavigation';
import Box from '@mui/material/Box';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import EditReminder from './features/reminder/EditReminder'
import ShowReminders from './features/reminder/ShowReminders'
import { Provider } from 'react-redux'
import { store } from './app/store'
import TodoList from './routes/todo_list'
import EditLabels from './features/label/EditLabels'
import {isLocalhost} from './features/env/environment'
import UpdateReminderStates from './features/reminder/UpdateReminderStates'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import Snackbar from '@mui/material/Snackbar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

let theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1b5ee8',
    },
    secondary: {
      main: '#aaa9bc',
    },
  },
  components: {
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: ''
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "transparent",
        }
      }
    }
  },
});

function AppRouter() {
  return (
    <div className="App">
      <Container maxWidth="md">
        <Box sx={{ pb: 7 }}>
          <Outlet />
        </Box>
        <MyBottomNavigation />
      </Container>  
    </div>
  );
}

type serviceWorkerState = {
  waitingWorker: ServiceWorker |null,
  newVersionAvailable: boolean
}

function App() {
  const [swStatus, setSwStatus] = React.useState<serviceWorkerState>({waitingWorker: null, newVersionAvailable: false})

  React.useEffect(() => {   
    let config = {
      onUpdate: (registration: ServiceWorkerRegistration) => {
        setSwStatus({
          waitingWorker: registration && registration.waiting, 
          newVersionAvailable: true
        })
      }
    }
    
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register(config);
  }, [swStatus])

  const updateServiceWorker = () => {
    swStatus.waitingWorker && swStatus.waitingWorker.postMessage({ type: 'SKIP_WAITING' })
    setSwStatus({...swStatus, newVersionAvailable: false })
    window.location.reload()
  }

  const handleClose = () => {
    setSwStatus({...swStatus, newVersionAvailable: false })
  };

  const snackbarAction = (
    <>
      <Button color="primary" size="small" onClick={updateServiceWorker}>
        Reload App
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (    
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />        
          <UpdateReminderStates></UpdateReminderStates>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<AppRouter />}>
                  <Route path="" element={<ShowReminders />} />
                  <Route path="show_reminders" element={<ShowReminders />} />
                  <Route path="show_reminders/:reminderFilterId" element={<ShowReminders />} />
                  <Route path="edit_reminder" element={<EditReminder />} />
                  <Route path="edit_reminder/:reminderId" element={<EditReminder />} />
                  <Route path="labels" element={<EditLabels />} />
                  <Route path="labels/:labelId" element={<EditLabels />} />
                  {isLocalhost && <Route path="todo_list" element={<TodoList />} />}
                </Route>
              </Routes>
            </BrowserRouter>
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={swStatus.newVersionAvailable}
              onClose={handleClose}
              message="A new version is available!"
              action={snackbarAction}
            />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  )
}

export default App;
