import ListReminders, { archivedLast } from '../features/reminder/ListReminders'
import { ReminderState } from '../features/reminder/reminderSlice';
import { v4 as uuidv4 } from 'uuid'
import {isLocalhost} from '../features/env/environment'
import {useRef} from 'react';
import { truncate } from 'fs/promises';

export default function TodoList() {
    const reminders: ReminderState[] = [
      // bugs
      {id: uuidv4(), subject: "view changes after saving configuration", archived: true, labels: [] },
      {id: uuidv4(), subject: "daterange picker is bigger then screen on my mobile", archived:false, labels: [] },

      // must have
      {id: uuidv4(), subject: "create and show Reminder", archived: true, labels: [] },
      {id: uuidv4(), subject: "add labels", archived: true, labels: [] },
      {id: uuidv4(), subject: "define how (many) labels to be displayed with a Reminder", archived: true, labels: [] },
      {id: uuidv4(), subject: "add filter for reminder view", archived: true, labels: [] },
      {id: uuidv4(), subject: "add daterange filter", archived: true, labels: [] },
      {id: uuidv4(), subject: "show only X weeks in advance", archived: true, labels: [] },
      {id: uuidv4(), subject: "filters show/hide archived", archived: true, labels: [] },
      {id: uuidv4(), subject: "store filter for reminder view as preset", archived: true, labels: [] },
      {id: uuidv4(), subject: "add repeating Reminder", archived: true, labels: [] },
      {id: uuidv4(), subject: "add user feedback formular", labels: [] },
      {id: uuidv4(), subject: "prevent Reminder behind bottom navbar", archived:true, labels: [] },
      {id: uuidv4(), subject: "allow caching for offline usage / synchronisation", archived:true, labels: [] },
      {id: uuidv4(), subject: "better icon and images", labels: [] },
      {id: uuidv4(), subject: "add tests", labels: [] },
      {id: uuidv4(), subject: "create help / introduction test if no reminders are set", labels: [] },
      {id: uuidv4(), subject: "add 'edit Reminder'", archived:true, labels: [] }, 
      {id: uuidv4(), subject: "persist filter and daterange in redux to store settings", archived:false, labels: [] }, 
      {id: uuidv4(), subject: "add 'reload App' after Deploy", archived:false, labels: [] }, 
      {id: uuidv4(), subject: "daterange toggle include/exclude if date is missing", archived:true, labels: [] },
      {id: uuidv4(), subject: "figure out how to handle Dates in the past", archived:true, labels: [] },
      {id: uuidv4(), subject: "Daterange Cancel not working", archived:true, labels: [] },
      {id: uuidv4(), subject: "catch and log all errors", archived:false, labels: [] }, // https://www.tek-tools.com/cloud/react-logging-and-error-handling-best-practices
      {id: uuidv4(), subject: "Move delete saved filter to save menu", archived:false, labels: [] },
      {id: uuidv4(), subject: "introduce save and save as, if a configuration was loaded", archived:false, labels: [] },
      {id: uuidv4(), subject: "Show full date", archived:false, labels: [] },
      {id: uuidv4(), subject: "Show short date", archived:false, labels: [] },
      {id: uuidv4(), subject: "setup remindme-test-appace.de and deploy there first", archived:false, labels: [] },

      // v2
      {id: uuidv4(), subject: "add calendar view", labels: [] },
      {id: uuidv4(), subject: "import calendar", labels: [] },
      {id: uuidv4(), subject: "add translations", labels: [] },
      {id: uuidv4(), subject: "force settings (language, date format, ...) on start screen", labels: [] },
      {id: uuidv4(), subject: "different date formats", labels: [] },
      {id: uuidv4(), subject: "backup Reminders, import export", labels: [] },
      {id: uuidv4(), subject: "add push notifications when Date is reached", labels: [] },
      {id: uuidv4(), subject: "add push notifications X days before Date is reached", labels: [] },

      // nice to have
      {id: uuidv4(), subject: "add multiline text", labels: [] }, 
      {id: uuidv4(), subject: "add priorities for labels", labels: [] }, 
      {id: uuidv4(), subject: "add url field", labels: [] }, 
      {id: uuidv4(), subject: "add image field", labels: [] }, 
      {id: uuidv4(), subject: "add icons and colors for Reminder", labels: [] }, 
      {id: uuidv4(), subject: "delete stored reminder filters", archived: true, labels: [] }, 
      {id: uuidv4(), subject: "delete or archive Reminder", archived: true, labels: [] }, 
      {id: uuidv4(), subject: "show or hide archived Reminder", archived: true, labels: [] }, 
      {id: uuidv4(), subject: "reuse archived Reminder when creating new Reminder", labels: [] }, 
      {id: uuidv4(), subject: "show Reminder with Date only X days before Date is reached", archived: true, labels: [] }, 
      {id: uuidv4(), subject: "add tile layout", archived: true, labels: [] }, 
      {id: uuidv4(), subject: "show todo list in app", archived: true, labels: [] }, 
      {id: uuidv4(), subject: "professional design / theme", labels: [] }, 
      {id: uuidv4(), subject: "negative text filter", labels: [] }, 
      {id: uuidv4(), subject: "negative label filter", labels: [] }, 
      {id: uuidv4(), subject: "store fingerprint (js / Canvas)", labels: [] }, 
      {id: uuidv4(), subject: "add label that hides the Reminder by default", archived: true, labels: [] }, 
      {id: uuidv4(), subject: "at rank to labels to force order", archived:false, labels: [] },
      {id: uuidv4(), subject: "show first label inline in list view", archived:false, labels: [] },
      {id: uuidv4(), subject: "show/hide top and bottom navigation/menu to increase available space", archived:false, labels: [] },

      // pro
      {id: uuidv4(), subject: "add user sharing", labels: [] }, 
      {id: uuidv4(), subject: "use indexeddb and event sourcing", labels: [] }, 
      {id: uuidv4(), subject: "choose favourite filters per device", labels: [] }, 
    ]

    const ref = useRef(null);
    return (
      <>
        <h1>List of planned features</h1>
        <div ref={ref}>
          {isLocalhost && ListReminders({
            reminders: reminders, 
            sort: archivedLast, 
            handleDelete: (id:string) => {}, 
            display: {displayType: 'list', showLabels: true, showSettings: false}, 
            ref: ref
          })}
        </div>
      </>
    )
  }