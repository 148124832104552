import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { selectLabels, LabelState } from './labelSlice'
import { useSelector } from 'react-redux'
import Label from './Label';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type selectLabelProps = {
  selectedLabels: string[],
  setSelectedLabels: (labels: string[]) => void,
  title: string
}

export default function SelectLabels({selectedLabels, setSelectedLabels, title}: selectLabelProps) {
  const labels: LabelState[] = useSelector(selectLabels)

  const handleChange = (event: SelectChangeEvent<typeof selectedLabels>) => {
    const {
      target: { value },
    } = event;
    setSelectedLabels(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  function getLabelFrom(id: string) {
    return labels.find(label => label.id === id)
  }

  const renderLabel = (id: string) => {
      let label = getLabelFrom(id)
      if (label === undefined) {
          return null
      }
      
      return <Label key={id} label={label} />
  }

  return (
    <div>
      <FormControl sx={{ minWidth: 300 }}>
        <InputLabel id="select-label">{title}</InputLabel>
        <Select
          labelId="select-label"
          id="multiple-label-select"
          multiple
          value={selectedLabels}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={title} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((id) => (
                renderLabel(id)
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {labels.map((label) => (
            <MenuItem
              key={label.id}
              value={label.id}
            >
              {label.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
