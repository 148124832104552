import {useEffect, useState} from 'react';
import Reminder, { getDayJs } from './Reminder'
import { ReminderState } from './reminderSlice'
import List from '@mui/material/List';
import { reminderDisplay } from './ReminderDisplay';
import Box from '@mui/material/Box';

export function sortByDateTimeAsc(a: ReminderState, b: ReminderState): -1|0|1 {
    if (!a.date) {
        return 1
    }
    if (!b.date) {
        return -1
    }

    return getDayJs(a.date, a.time).isBefore(getDayJs(b.date, b.time)) ? -1 : 1
}

export function archivedLast(a: ReminderState, b: ReminderState): -1|0|1 {
    if (!a.archived) {
        return -1
    }
    if (!b.archived) {
        return 1
    }

    return 0
}

export function noSort(a: ReminderState, b: ReminderState): -1|0|1 {
    return 0
}

type ListReminderProps = {
    reminders: ReminderState[], 
    sort: (a: ReminderState, b: ReminderState) => -1|0|1, 
    handleDelete: (id: string) => void, 
    display: reminderDisplay, 
    ref: any
}

export default function ListReminders({reminders, sort, handleDelete, display, ref}: ListReminderProps) {
    
    const [width, setWidth] = useState(0)

    useEffect(() => {
        setWidth(ref.current.offsetWidth)
    }, []);

    const renderedRemindersItems = [...reminders].sort(sort).map(reminder => {
        return <Reminder key={reminder.id} reminder={reminder} handleDelete={handleDelete} display={display}/>
    })

    const renderList = () => { 
        return (
            <List>
                {renderedRemindersItems}
            </List>
        );        
    }
    const renderGrid = () => {
        return <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: 1,
                '& > :not(style)': {
                    m: 0.3,
                    p: 1,
                    width: {xs: (width/3-5), sm: (width/5-5)},
                    height: {xs: (width/3-5), sm: (width/5-5)},
                    fontSize: 'smaller',
                },
            }}
        >
            {renderedRemindersItems}
        </Box>
    }
    const renderCalendar = () => {
        return <>{renderedRemindersItems}</>    
    }

    let renderFunctions = {
        'list': renderList,
        'grid': renderGrid,
        'calendar': renderCalendar
    }

    return renderFunctions[display.displayType]()
}