import { configureStore, PayloadAction } from '@reduxjs/toolkit'
import reminderSlice from '../features/reminder/reminderSlice';
import labelSlice from '../features/label/labelSlice';
import { MiddlewareAPI, Dispatch } from 'redux';
import reminderFilterSlice from '../features/reminder/reminderFilterSlice';

const storeStateMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: PayloadAction) => {
    const result = next(action)

    if (action.type) {
        const actionCategory = action.type.split('/')[0]
        const currentState = store.getState()[actionCategory] ?? ""

        localStorage.setItem(actionCategory, JSON.stringify(currentState))
    }

    return result
}

export const store = configureStore({
    reducer: {
        reminders: reminderSlice,
        reminderFilters: reminderFilterSlice,
        labels: labelSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(storeStateMiddleware)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch