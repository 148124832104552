import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../../app/store'
import {reminderDisplay} from './ReminderDisplay'
import {reminderFilterSettings} from './ReminderFilter'
import {quickFilter} from './ReminderDateRange'

export type dateRangeFilters = {
  startDate?: string,
  endDate?: string,
  selectedQuickFilter: quickFilter | null,
  includePast: boolean,
  includeWithoutDate: boolean,
}

export interface ReminderFilterState {
  id: string, // unique identifier for each reminder
  name: string, // the subject, or a short description 
  filter?: reminderFilterSettings
  dateRange?: dateRangeFilters,
  display?: reminderDisplay
}

const sliceName = 'reminderFilters'

let initialState: ReminderFilterState[] = []

let persistedState = localStorage.getItem(sliceName)
if (persistedState) {
  initialState = JSON.parse(persistedState)
}

const reminderFilterSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reminderFilterAdded(state, action: PayloadAction<ReminderFilterState>) {
      state.push(action.payload)
    },
    reminderFilterDeleted(state, action: PayloadAction<string>) {
      return state.filter(reminder => reminder.id !== action.payload)
    },
  }
})

export const { reminderFilterAdded, reminderFilterDeleted } = reminderFilterSlice.actions
export const selectReminderFilters = (state: RootState) => state[sliceName]

export default reminderFilterSlice.reducer
