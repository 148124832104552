import * as React from 'react'
import {useSelector} from 'react-redux'
import {selectLabels, LabelState, labelAdded, labelUpdated, labelDeleted} from './labelSlice'
import Label from './Label'
import List from '@mui/material/List'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import {useState} from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import {v4 as uuidv4} from 'uuid'
import {useAppDispatch} from '../../app/hooks'
import Typography from '@mui/material/Typography'
import AlertDialog from '../user_input/AlertDelete'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import {useNavigate, useParams} from 'react-router-dom'

interface LabelColors {
    bg: string,
    color: string,
    name: string
}

// see https://www.w3schools.com/cssref/css_colors.asp
const colors: LabelColors[] = [    
    {bg: '#ffffff', color: 'black', name: "White"},
    {bg: '#FAEBD7', color: 'black', name: 'AntiqueWhite'},
    {bg: '#FF69B4', color: 'black', name: 'HotPink'},
    {bg: '#DC143C', color: 'white', name: 'Crimson'},
    {bg: '#ff0000 ', color: 'white', name: 'Red'},
    {bg: '#8A2BE2', color: 'white', name: 'BlueViolet'},
    {bg: '#0000ff ', color: 'white', name: 'Blue'},
    {bg: '#1E90FF', color: 'white', name: 'DodgerBlue'},
    {bg: '#6495ED', color: 'white', name: 'CornflowerBlue'},
    {bg: '#00FFFF', color: 'black', name: 'Cyan'},
    {bg: '#7FFFD4', color: 'black', name: 'Aquamarine'},
    {bg: '#8FBC8F', color: 'black', name: 'DarkSeaGreen'},
    {bg: '#5F9EA0', color: 'white', name: 'CadetBlue'},
    {bg: '#008B8B', color: 'white', name: 'DarkCyan'},
    {bg: '#008000 ', color: 'white', name: 'Green'},
    {bg: '#7FFF00', color: 'black', name: 'Chartreuse'},
    {bg: '#ffff00 ', color: 'black', name: 'Yellow'},
    {bg: '#FFD700', color: 'black', name: 'Gold'},
    {bg: '#FF8C00', color: 'black', name: 'DarkOrange'},
    {bg: '#A52A2A', color: 'white', name: 'Brown'},
    {bg: '#000000', color: 'white', name: 'Black'},
]

function ColorMenu(props: {callback: (color: LabelColors) => void}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(10);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuItemClick = (
      event: React.MouseEvent<HTMLElement>,
      index: number,
    ) => {
      setSelectedIndex(index);
      setAnchorEl(null);
      props.callback(colors[index]);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <List
          component="nav"
          aria-label="label color"
          sx={{ bgcolor: 'background.paper' }}
        >
          <ListItem
            button
            id="lock-button"
            aria-haspopup="listbox"
            aria-controls="lock-menu"
            aria-label="select a color"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickListItem}
          >
            <ListItemText
              primary="Select a color"
              secondary={colors[selectedIndex].name}
            />
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          {colors.map((color, index) => (
            <MenuItem
              key={color.bg}
              onClick={(event) => handleMenuItemClick(event, index)}
              sx={{ bgcolor: color.bg, color: color.color }}
            >
              {color.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }

type Label = {
  id: string, 
  name: string, 
  hidden: boolean, 
  color: LabelColors,
}

export default function EditLabels() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const labels: LabelState[] = useSelector(selectLabels)

    const { labelId } = useParams()

    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedLabelId, setSelectedLabelId] = React.useState("");

    const [label, setLabel] = useState<Label>({
      id: uuidv4(), 
      name: "example", 
      hidden: false, 
      color: colors[10]
    })
    
    React.useEffect(() => {    
      if (undefined === labelId) {
          return
      }

      let label = labels.filter(label => label.id === labelId)[0]

      if (label === undefined) {
        return
      }
      
      let color = colors.find(color => color.bg === label.bgcolor && color.color === label.color) || {
        bg: label.bgcolor,
        color: label.color,
        name: label.bgcolor
      }
          
      setLabel({
        id: label.id, 
        name: label.name, 
        hidden: label.hidden || false, // || false is only necessary because older labels might not contain the hidden attribute
        color: color
      })
      
    }, [labelId, labels]);

    const deleteLabel = (id: string) => {
      dispatch(labelDeleted(id))
      setOpenDialog(false);
      resetInputFields()
    };  

    const openDeleteDialog = (id: string) => {
        setSelectedLabelId(id)
        setOpenDialog(true)
    }

    const renderedLabelItems = [...labels].map(label => {
        return <Label 
          label={label} 
          handleDelete={(id: string) => {openDeleteDialog(id)}} 
          onClick={(id: string) => {navigate("/labels/" + id)}}
        />
    })

    const labelAsLabelState = (label: Label) => {
      return {
          id: label.id,
          name: label.name, 
          hidden: label.hidden,
          bgcolor: label.color.bg, 
          color: label.color.color,           
      }
    }

    const createLabel = () => {        
      dispatch(labelAdded(labelAsLabelState(label)))
      resetInputFields()
    }

    const updateLabel = () => {        
      dispatch(labelUpdated(labelAsLabelState(label)))
      resetInputFields()
    }

    const resetInputFields = () => {
      setLabel({
        id: uuidv4(), 
        name: "example", 
        hidden: false, 
        color: colors[10]
      })   
      navigate("/labels/")
    }

    return (
        <List>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField 
                                required 
                                label="Label Name" 
                                variant="standard"                                
                                value={label.name}      
                                onChange={event => setLabel({...label, name: event.target.value})}
                            />
                        </Grid>
                        <Grid item xs={6}>
                          <ColorMenu callback={(color: LabelColors) => setLabel({...label, color})}/>
                        </Grid>
                        <Grid item xs={12}>                     
                          <FormGroup>
                            <FormControlLabel 
                                control={<Switch 
                                    checked={label.hidden} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setLabel({...label, hidden: event.target.checked})}}
                                />} 
                                label={"Hidden (Show Reminder only if filtered by label)"} 
                            />
                        </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                          <Label label={labelAsLabelState(label)}></Label>
                        </Grid>
                        {labelId === undefined && <Grid item xs={8}>
                          <Button variant="contained" color="primary" onClick={createLabel}>Create Label</Button>
                        </Grid>}
                        {labelId !== undefined && <Grid item xs={12}>
                          <Button sx={{marginBottom: {xs: 1, sm: 0}, marginRight: {xs: 0, sm: 2}}} variant="contained" color="secondary" onClick={() => {resetInputFields(); navigate("/labels/")}}>Cancel</Button>
                          <Button sx={{marginBottom: {xs: 1, sm: 0}, marginRight: {xs: 0, sm: 2}}} variant="contained" color="secondary" onClick={event => openDeleteDialog(labelId)}>Delete Label</Button>
                          <Button variant="contained" color="primary" onClick={updateLabel}>Update Label</Button>
                        </Grid>}
                    </Grid>
                </CardContent>
            </Card>
            {labels.length > 0 && <Card sx={{marginTop: 2}}>
                <CardContent>
                    <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                        Your labels
                    </Typography>
                    {renderedLabelItems}
                    <Typography sx={{ fontSize: 14, marginTop: 2 }} color="text.secondary" gutterBottom>
                        Click on a label to edit it
                    </Typography>
                </CardContent>
            </Card>}
            <Card sx={{marginTop: 2}}>
              <CardContent>
                <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                    Why do I need labels?
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    &nbsp;&nbsp;&nbsp;&nbsp;Labels can be used to cluster your reminders. 
                    You can filter by label and use those filters as a preset for easy access to custom views. 
                    You'll be able to share specific reminders with other accounts by label (in a later version).
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;You can think of labels as a better version to create lists, folders or groups, because each reminder can be part of multiple lists, groups or folders.
                </Typography>
              </CardContent>
            </Card>
            <AlertDialog open={openDialog} setOpen={setOpenDialog} id={selectedLabelId} handleSubmit={deleteLabel} />
        </List>        
    )
}