import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Grid from '@mui/material/Grid'
import {TextField} from '@mui/material'
import SelectLabels from '../label/SelectLabels'
import {Link} from 'react-router-dom'
import Paper from '@mui/material/Paper'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import {dateRangeFilters} from './ReminderDateRange'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

export type reminderFilterSettings = {
    searchText: string,
    selectedLabels: string[],
    showArchived: boolean,
}

type reminderFilterProps = {
    filters: reminderFilterSettings,
    setFilters: React.Dispatch<React.SetStateAction<reminderFilterSettings>>,
}

export default function ReminderFilter(filter: reminderFilterProps & dateRangeFilters) {
    const subjectRef = React.createRef<HTMLInputElement>()
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    const handleReset = () => {
        filter.setFilters({...filter.filters, selectedLabels: [], searchText: "", showArchived: true})
        if (subjectRef.current) {
        subjectRef.current.value = ""
        }
        setAnchorEl(null)
    }

    return (
        <Grid container justifyContent="center">       
            <Button
                id="filter-button"
                aria-controls={open ? 'filter-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                size="small"
                sx={{padding: 0}}
            >
                <FilterAltIcon /> <ArrowDropDownIcon />
            </Button>
            <Paper sx={{ width: 320, maxWidth: '100%' }}>
                <Menu
                    id="filter-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'filter-button',
                    }}
                >
                    <MenuItem>
                        <TextField 
                            label="Filter by text" 
                            inputRef={subjectRef} 
                            value={filter.filters.searchText}
                            onChange={event => {filter.setFilters({...filter.filters, searchText: subjectRef?.current?.value || ""})}}
                        />
                    </MenuItem>
                    <MenuItem>
                        <SelectLabels 
                            selectedLabels={filter.filters.selectedLabels} 
                            setSelectedLabels={(labels: string[]) => {filter.setFilters({...filter.filters, selectedLabels: labels})}} 
                            title="Filter by label" 
                        />
                    </MenuItem>
                    <MenuItem>                        
                        <FormGroup>
                            <FormControlLabel 
                                control={<Switch 
                                    checked={filter.filters.showArchived} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {filter.setFilters({...filter.filters, showArchived: event.target.checked})}}
                                />} 
                                label={"Show archived Reminders"} 
                            />
                        </FormGroup>
                    </MenuItem>
                    <MenuItem>
                        <Grid container spacing={2} columns={12} alignItems="center">
                            <Grid item xs={6}>
                                <Button component={Link} to="/show_reminders" color="secondary" onClick={handleReset}>Reset</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button color="primary" onClick={handleClose}>Filter</Button>
                            </Grid>
                        </Grid>
                    </MenuItem>
                </Menu>
            </Paper>
        </Grid>
    )
  }