import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Grid from '@mui/material/Grid'
import { useAppDispatch } from '../../app/hooks'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ViewListIcon from '@mui/icons-material/ViewList'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

export type displayType = 'list' | 'grid' | 'calendar'

export type reminderDisplay = {
    displayType: displayType,
    showLabels: boolean,
    showSettings: boolean
}

type reminderDisplayProps = {
    filters: reminderDisplay,
    setFilters: React.Dispatch<React.SetStateAction<reminderDisplay>>,
}

export default function ReminderDisplay({filters, setFilters}: reminderDisplayProps) {
    const dispatch = useAppDispatch()
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }

    return (
        <>
            <Grid container justifyContent="center">       
                <Button
                    id="display-button"
                    aria-controls={open ? 'display-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    size="small"
                    sx={{padding: 0}}
                >
                    <DisplaySettingsIcon /> <ArrowDropDownIcon />
                </Button>
                <Paper sx={{ width: 320, maxWidth: '100%' }}>
                    <Menu
                        id="display-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'display-button',
                        }}
                    >
                        <DisplayMenu filters={filters} setFilters={setFilters}/>
                    </Menu>
                </Paper>
            </Grid>
        </>
    )
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function DisplayMenu({filters, setFilters}: reminderDisplayProps) {
    const [value, setValue] = React.useState(0);
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    const handleDisplayType = (
        event: React.MouseEvent<HTMLElement>,
        newDisplayType: displayType,
      ) => {
        setFilters({...filters, displayType: newDisplayType});
    };
  
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Sorting" id="simple-tab-0" aria-controls='simple-tabpanel-0' />
                <Tab label="Display" id="simple-tab-1" aria-controls='simple-tabpanel-1' />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
            TODO: Sorting
        </TabPanel>
        <TabPanel value={value} index={1}>          
            <ToggleButtonGroup
                value={filters.displayType}
                exclusive
                onChange={handleDisplayType}
                aria-label="text alignment"
            >
                <ToggleButton value="list" aria-label="List">
                    <ViewListIcon />
                </ToggleButton>
                <ToggleButton value="grid" aria-label="Grid">
                    <ViewModuleIcon />
                </ToggleButton>
                <ToggleButton value="calendar" aria-label="Calendar">
                    <CalendarViewMonthIcon />
                </ToggleButton>
            </ToggleButtonGroup>    
            <FormGroup>               
              {filters.displayType === 'list' && <FormControlLabel 
                    control={<Switch 
                        checked={filters.showLabels} 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setFilters({...filters, showLabels: event.target.checked})}}
                    />} 
                    label={"Show labels"} 
              />}
              <FormControlLabel 
                    control={<Switch 
                        checked={filters.showSettings} 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setFilters({...filters, showSettings: event.target.checked})}}
                    />} 
                    label={"Display settings & filters"} 
                />
            </FormGroup>
        </TabPanel>
      </Box>
    );
  }
