import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'

type AlertDeleteProps = {
    open: boolean, 
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    id: string,
    handleSubmit: (id: string) => void
}

export default function AlertDelete({open, setOpen, id, handleSubmit}: AlertDeleteProps) {
    const handleCancel = () => {
        setOpen(false);
    };
  
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete this item? 
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {handleSubmit(id)}}>Delete</Button>
            <Button onClick={handleCancel} autoFocus>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}