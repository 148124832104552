// this class is only used to update the Dates of Reminders
import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectReminders, ReminderState, RepetitionState, RepetitionType, RepetitionPeriod, reminderUpdated, dateFormat } from './reminderSlice'
import { getDayJs } from './Reminder' 
import { useAppDispatch } from '../../app/hooks'
import dayjs, { Dayjs } from 'dayjs'

function getDayJsUnit(period: RepetitionPeriod) {
    let dayJsUnit = new Map()
    dayJsUnit.set(RepetitionPeriod.Daily, 'day')
    dayJsUnit.set(RepetitionPeriod.Weekly, 'week')
    dayJsUnit.set(RepetitionPeriod.Monthly, 'month')
    dayJsUnit.set(RepetitionPeriod.Annualy, 'year')

    return dayJsUnit.get(period)
}

const ONE_MINUTE_IN_MS = 10000

export function getNextDate(datetime: Dayjs, repetition: RepetitionState): Dayjs {
    return datetime.add(repetition.cycle, getDayJsUnit(repetition.period))
}

export function reminderNeedsRepetition(reminder: ReminderState, type: RepetitionType) {
    if (reminder.repetition === null || reminder.repetition === undefined) {
        return false
    }
    if (reminder.repetition.type !== type) {
        return false
    }
    if (reminder.repetition.type === RepetitionType.Relative) {
        return true
    }

    if (reminder.date === null || reminder.date === undefined) {
        return false
    }
    if (reminder.repetition.type === RepetitionType.Fixed) {
        let targetDate = getDayJs(reminder.date, reminder.time)

        return targetDate.isBefore(dayjs())
    }

    return false
}

export default function UpdateReminderStates() {
    let reminders: ReminderState[] = useSelector(selectReminders)
    const dispatch = useAppDispatch()

    React.useEffect(() => {        
        const updateRemindersIfDateIsPassed = () => { 
            for (var reminder of reminders) {  
                if (!reminderNeedsRepetition(reminder, RepetitionType.Fixed)) {
                    continue
                }
                
                let targetDate = getDayJs(reminder.date as string, reminder.time)
                let updateNeeded = false
                let nextReminder = reminder

                while (targetDate.isBefore(dayjs())) {
                    const nextDate = getNextDate(targetDate, reminder.repetition as RepetitionState)

                    nextReminder = {
                        ...reminder,
                        date: nextDate.format(dateFormat),
                        archived: false
                    }
                     
                    updateNeeded = true
                    targetDate = nextDate
                }

                if (updateNeeded) {
                    dispatch(reminderUpdated(nextReminder))
                }
            }
        }

        updateRemindersIfDateIsPassed()
        const timer = setInterval(() => {
            updateRemindersIfDateIsPassed()            
        }, 6 * ONE_MINUTE_IN_MS);
    }, [dispatch, reminders]);

    return (<></>)
}