import React from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import PersonOutline from '@mui/icons-material/PersonOutline'
import Chip from '@mui/material/Chip'
import EditIcon from '@mui/icons-material/Edit'
import TaskAlt from '@mui/icons-material/TaskAlt'
import Restore from '@mui/icons-material/Restore'
import Delete from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import { ReminderState, dateFormat, timeFormat } from './reminderSlice'
import dayjs, { Dayjs } from 'dayjs'
import { useAppDispatch } from '../../app/hooks'
import { reminderArchived, reminderDone, RepetitionType } from './reminderSlice'
import { selectLabels, LabelState } from '../label//labelSlice'
import { useSelector } from 'react-redux'
import Label from '../label/Label'
import Box from '@mui/material/Box'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { styled, alpha } from '@mui/material/styles'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useNavigate } from "react-router-dom"
import { reminderDisplay } from './ReminderDisplay'
import Card from '@mui/material/Card'

interface TitleProps {
    reminder: ReminderState,
    handleDelete: (id: string) => void,
    display: reminderDisplay
}

export function getDayJs(date: string, time?: string): Dayjs {
    if (time === null || time === undefined || time === "" ) {
        time = dayjs().format(timeFormat) // use current time to create a proper formatting
    }

    return dayjs(date + " " + time, dateFormat + " " + timeFormat)
}

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export function hasFixedRepetition(reminder: ReminderState) {
    if (reminder.repetition === null || reminder.repetition === undefined) {
        return false
    }
    if (reminder.repetition.type === RepetitionType.Fixed) {
        return true
    }
    return false
}
export function hasRelativeRepetition(reminder: ReminderState) {
    if (reminder.repetition === null || reminder.repetition === undefined) {
        return false
    }
    if (reminder.repetition.type === RepetitionType.Relative) {
        return true
    }
    return false
}

type EditMenuProps = {
    reminderId: string,
    handleDelete: (id: string) => void,
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '0 0',
      },
      '& .MuiMenuItem-root': {
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
}));

function EditMenu({reminderId, handleDelete} : EditMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const navigate = useNavigate()

    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    };
    const handleClose = () => {
        setAnchorEl(null)
    };
  
    return (
      <>
        <IconButton
          aria-label="more"
          id={"edit-menu-" + reminderId}
          aria-controls={open ? 'edit-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <StyledMenu
          id="edit-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
            <MenuItem key={"edit-menu-item"+reminderId} onClick={() => navigate("/edit_reminder/" + reminderId)}>
                <ListItemIcon><EditIcon/></ListItemIcon>
                <ListItemText>Edit</ListItemText>
            </MenuItem>                             
            <MenuItem key={"delete-menu-item"+reminderId} onClick={(event) => {handleDelete(reminderId); handleClose()}}>
                <ListItemIcon><Delete /></ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
        </StyledMenu>
      </>
    );
  }

export default function Reminder({reminder, handleDelete, display}: TitleProps) {
    const dispatch = useAppDispatch()
    const labels: LabelState[] = useSelector(selectLabels)
    
    function done() {
        dispatch(reminderDone(reminder.id))        
    }
    function archive() {
        dispatch(reminderArchived(reminder.id))        
    }

    function getLabelFrom(id: string) {
      return labels.find(label => label.id === id)
    }

    function dateTimeLabel() {
        let dateTimeLabel = dateTimeToLabel(reminder.date, reminder.time)
        
        return dateTimeLabel !== null
            ? <Chip label={dateTimeLabel} color={dateTimeLabelColor(reminder.date, reminder.time)} variant="outlined" size="small"/>
            : null
    }

    function adornment() {
        return {
            startAdornment: (
                <InputAdornment position="start">
                    <PersonOutline />
                </InputAdornment>
            ),
            endAdornment: (
                <InputAdornment position="end">
                    {dateTimeLabel()}   
                    {archiveIcons()} 
                    <EditMenu reminderId={reminder.id} handleDelete={handleDelete} />             
                </InputAdornment>
            )
        };
    }

    function archiveIcons() {
        return (
            <>            
            {!reminder.archived && !hasFixedRepetition(reminder) && <IconButton
                aria-label="done"
                title="Done"
                color="success"
                onClick={done}
            >
                <TaskAlt />
            </IconButton>}                   
            {reminder.archived && <IconButton
                aria-label="done"
                title="Done"
                color="secondary"
                onClick={archive}
            >
                <Restore />
            </IconButton>}   
            </>
        )
    }

    function dateTimeToLabel(date?: string, time?: string) {
        if (date === null || date === undefined) {
            return null
        }
        let targetDate: any = getDayJs(date, time)

        return targetDate.fromNow()
    }

    function dateTimeLabelColor(date?: string, time?: string) {
        if (date === null || date === undefined) {
            return "info"
        }
        let targetDate: any = getDayJs(date, time)

        if (targetDate === null) {
            return "info"
        }
        if (targetDate.isBefore(dayjs())) {
            return "error"
        }
        if (targetDate.diff(dayjs(), 'hour') < 24) {
            return "warning"
        }
        
        return "info"
    }
    
    let inputProps = {readOnly: true, ...adornment()};

    const ArchivedTextField = styled(TextField)({
        '& .MuiInputBase-input': {
            textDecoration: "line-through",
        }
    });

    const TextFieldModal = reminder.archived === true ? ArchivedTextField : TextField

    const shortLabel = (label: string) => {
        return label.length > 15 ? 10 : 0
    }

    const reduceLength = (arr: any[]) => {
        let res = [...arr]
        let maxLength = Math.min(Math.floor(window.screen.width / 100), 7)
        if (res.length > maxLength) {
            res.length = maxLength
        }
        return res
    }

    const renderLabel = (id: string) => {
        let label = getLabelFrom(id)
        if (label === undefined) {
            return null
        }
        
        return <Label key={id} label={label} short={shortLabel(label.name)}/>
    }

    const renderList = () => {        
        return (
            <Box> 
                <TextFieldModal
                    fullWidth
                    defaultValue={reminder.subject}
                    variant="standard"
                    margin="dense"          
                    size="small"
                    InputProps={inputProps}
                />     
                {display.showLabels && reminder.labels !== undefined && <Box 
                    sx={{
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        alignItems: 'flex-end',
                    }} 
                    component="div"
                >      
                    {reminder.labels !== undefined && reduceLength(reminder.labels).map((id) => (
                        renderLabel(id)
                    ))}
                </Box>}
            </Box>
        )
    }
    const renderGrid = () => {
        return (
            <Card>                
                <Box component="div" sx={{clear:'both', height:'30%', width:'100%', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    {reminder.subject}
                </Box>
                <Box component="div" sx={{clear:'both', height:'70%', width:'100%', position: 'relative'}}>
                    {dateTimeLabel()} 
                    <Box sx={{position: 'absolute', bottom: 0, height:'50%'}}>
                        {archiveIcons()}
                        <EditMenu reminderId={reminder.id} handleDelete={handleDelete} />
                    </Box>
                </Box>
            </Card>  
        )
    }
    const renderCalendar = () => {
        return <Box></Box>
    }

    let renderFunctions = {
        'list': renderList,
        'grid': renderGrid,
        'calendar': renderCalendar
    }

    return renderFunctions[display.displayType]()
}