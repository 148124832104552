import * as React from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import {TextField} from '@mui/material'
import FormDialog from '../user_input/FormDialog'
import {useAppDispatch} from '../../app/hooks'
import {reminderFilterAdded, ReminderFilterState, selectReminderFilters, reminderFilterDeleted, dateRangeFilters as dateRangeFilterState} from './reminderFilterSlice'
import {v4 as uuidv4} from 'uuid'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import {currentDateRangeSettingsToChips, currentFilterSettingsToChips, displaySettingsToChips} from './ShowReminders'
import SaveIcon from '@mui/icons-material/Save'
import {reminderFilterSettings} from './ReminderFilter'
import {reminderDisplay as reminderDisplaySettings} from './ReminderDisplay'
import {dateRangeFilters as dateRangeFilterSettings} from './ReminderDateRange'
import {dateFormat} from './reminderSlice'
import {useSelector} from 'react-redux'
import {selectLabels, LabelState} from '../label/labelSlice'
import {useNavigate} from "react-router-dom"
import Paper from '@mui/material/Paper'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {useParams} from 'react-router-dom'
import Divider from '@mui/material/Divider'
import { Link } from 'react-router-dom'

type saveProps = {
    filter: reminderFilterSettings,
    display: reminderDisplaySettings,
    dateRange: dateRangeFilterSettings
}

export default function Save(filter: saveProps) {
    const dispatch = useAppDispatch()
    const { reminderFilterId } = useParams()
    const reminderFilters = useSelector(selectReminderFilters)
    const saveFilterNameRef = React.createRef<HTMLInputElement>()
    
    const [saveDisplaySettings, setSaveDisplaySettings] = React.useState(true)
    const [saveDateRangeSettings, setSaveDateRangeSettings] = React.useState(true)
    const [saveFilterSettings, setSaveFilterSettings] = React.useState(true)
    const [openDialog, setOpenDialog] = React.useState(false)
    const [saveFilterError, setSaveFilterError] = React.useState("")

    const labels: LabelState[] = useSelector(selectLabels)

    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }

    const handleSave = () => {
        setOpenDialog(true)
    }
    const saveFilter = () => {
        if (saveFilterNameRef.current && saveFilterNameRef.current.value !== "") {
            const reminderFilter: ReminderFilterState = {
                id: uuidv4(),
                name: saveFilterNameRef.current.value, 
                filter: saveFilterSettings ? filter.filter : undefined,
                dateRange: saveDateRangeSettings ? convertedDateRangeSettings(filter.dateRange) : undefined,
                display: saveDisplaySettings ? filter.display : undefined,
            }
            dispatch(reminderFilterAdded(reminderFilter))
            setOpenDialog(false)
            navigate("/show_reminders/" + reminderFilter.id)
        } else {
            setSaveFilterError("Empty filter name is not allowed")
        }
    }
    const convertedDateRangeSettings = (dateRange: dateRangeFilterSettings): dateRangeFilterState => {
        return {
            startDate: dateRange.startDate !== null ? dateRange.startDate.format(dateFormat) : undefined,
            endDate: dateRange.endDate !== null ? dateRange.endDate.format(dateFormat) : undefined,
            selectedQuickFilter: dateRange.selectedQuickFilter,
            includePast: dateRange.includePast,
            includeWithoutDate: dateRange.includeWithoutDate,
        }
    }

    function getLabelFrom(id: string) {
      const label = labels.filter(label => label.id === id)
      return label[0]
    }
    
    const renderDeleteFilter = (reminderFilterId: string|undefined) => {
        const reminderFilter = reminderFilters.filter(reminderFilter => reminderFilter.id === reminderFilterId)
        
        if (reminderFilter.length === 0) {      
            return []
        }      
        
        return [
                <Divider key="menu_delete_divider"/>,
                <MenuItem  key="menu_delete_item">
                    <Button color="secondary" component={Link} to="/show_reminders" onClick={handleDelete}>Delete Filter "{reminderFilter[0].name}"</Button>
                </MenuItem> 
        ]
    }
    const handleDelete = () => {
        if (reminderFilterId !== undefined) {
            dispatch(reminderFilterDeleted(reminderFilterId))
        }
    }

    return (
        <>
            <Grid container justifyContent="center">       
                <Button
                    id="save-button"
                    aria-controls={open ? 'save-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    size="small"
                    sx={{padding: 0}}
                >
                    <SaveIcon /> <ArrowDropDownIcon />
                </Button>
            </Grid>
            <Paper sx={{ width: 320, maxWidth: '100%' }}>
                <Menu
                    id="save-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'save-button',
                    }}
                >
                    <MenuItem>
                        <Grid container justifyContent="flex-end">
                            <Button onClick={() => {handleClose(); handleSave();}}>Save</Button>
                        </Grid>
                    </MenuItem>
                    {renderDeleteFilter(reminderFilterId)}
                </Menu>
            </Paper>
            <FormDialog 
                open={openDialog} 
                setOpen={setOpenDialog} 
                title="Save Configuration" 
                description="Pick a name to store the current configuration"
                handleAction={saveFilter}
                actionLable="Save"
            >
                <>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="filterName"
                        label="Configuration Name"
                        type="text"
                        inputRef={saveFilterNameRef}    
                        onChange={event => {setSaveFilterError("")}}
                        error={saveFilterError !== ""}
                        helperText={saveFilterError}
                        fullWidth
                        variant="standard"
                    />
                    <FormGroup>
                        <FormControlLabel 
                            control={<Switch 
                                checked={saveDisplaySettings} 
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setSaveDisplaySettings(event.target.checked)}} 
                            />} 
                            label={"Add display settings"} 
                        />
                    </FormGroup>
                    {displaySettingsToChips(filter.display)}
                    <FormGroup>
                        <FormControlLabel 
                            control={<Switch 
                                checked={saveDateRangeSettings} 
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setSaveDateRangeSettings(event.target.checked)}} 
                            />} 
                            label={"Add date range settings"} 
                        />
                    </FormGroup>
                    {currentDateRangeSettingsToChips(filter.dateRange)}
                    <FormGroup>
                        <FormControlLabel 
                            control={<Switch 
                                checked={saveFilterSettings} 
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setSaveFilterSettings(event.target.checked)}} 
                            />} 
                            label={"Add filter settings"} 
                        />
                    </FormGroup>
                    {currentFilterSettingsToChips(filter.filter, getLabelFrom)}
                </>
            </FormDialog>
        </>
    )
  }