import Chip from "@mui/material/Chip";
import { LabelState } from "./labelSlice";

type LabelProps = {
    label: LabelState,
    handleDelete?: (id: string) => void,
    short?: number,
    onClick?: (id: string) => void
}

export default function Label({label, handleDelete, short, onClick}: LabelProps) {       
    let fieldProps: any = {
        sx: {
            backgroundColor: label.bgcolor,
            color: label.color
        },
        size: "small", 
        variant: "filled", 
        label: short !== undefined && short > 0 ? label.name.substring(0, short).toLowerCase() : label.name
    }

    if (handleDelete !== undefined) {
        const constHandleDelete = handleDelete
        fieldProps.onDelete = () => {constHandleDelete(label.id)}
    }

    if (onClick !== undefined) {
        fieldProps.onClick = () => {onClick(label.id)}
    }

    return (
        <Chip {...fieldProps}/>
    )
}